import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { breakPoints, colors, measurments } from "../../utils/config";
import IconButton from "../../sharedUI/IconButton";

const StyledPartNav = styled.div`
  .part-nav-popover {
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in-out;
    position: absolute;
    bottom: 5.5rem;
    right: 1rem;
    background: ${colors.neutral[10]};
    border-radius: ${measurments.borderRadius}px;
    border: 1px solid ${colors.neutral[60]};
  }
  .popover-button {
    gap: 0rem;
    margin-left: 1rem;
    padding: 0.3rem 1rem;
    svg {
      path {
        stroke: ${colors.neutral[70]};
      }
    }
    &:hover {
      svg {
        path {
          stroke: ${colors.neutral[10]};
        }
      }
    }
  }
  &.expanded {
    .part-nav-popover {
      opacity: 1;
      pointer-events: initial;
    }
  }
  ${breakPoints.mobile} {
    .part-nav-popover {
      width: calc(100vw - 2rem);
      bottom: 4.5rem;
    }
    .popover-button {
      margin-left: 0;
    }
  }
`;

const PartNav = ({ course, partIndex }) => {
  const [expanded, setExpanded] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (!ref.current) return;
      if (!ref.current.contains(target)) setExpanded(false);
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    //eslint-disable-next-line
  }, []);

  return (
    <StyledPartNav className={`${expanded ? "expanded" : ""}`} ref={ref}>
      <IconButton
        className="popover-button"
        iconLeft="hamburger"
        onClick={() => setExpanded(!expanded)}
      >
        Byt kapitel
      </IconButton>
      <div className="part-nav-popover">
        <div className="course-parts-container">
          {course.parts.map((part, i) => (
            <PartNavInner
              close={() => setExpanded(false)}
              key={part.uuid}
              part={part}
              partIndex={partIndex}
              course={course}
              i={i}
            />
          ))}
          <PartNavInner
            close={() => setExpanded(false)}
            part={{
              name: "Kunskapstest",
              contents: [],
            }}
            course={course}
            i={course.parts.length}
            partIndex={partIndex}
            icon="brain"
          />
        </div>
      </div>
    </StyledPartNav>
  );
};

export default PartNav;

const StyledPartNavInner = styled.div`
  .part-inner-name {
    transition: 200ms ease-in-out;
    background: ${colors.neutral[10]};
    display: flex;
    justify-content: space-between;
    padding: 0.575rem 1.125rem;
    align-items: center;
    white-space: nowrap;
    gap: 1rem;
    border-radius: ${measurments.borderRadius}px;
    &-left {
      h3 {
        color: ${colors.primary.hover};
      }
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        transition: 200ms ease-in-out;
      }
    }
    .open-button {
      cursor: pointer;
    }
  }
  .part-inner-headers {
    padding: 0.5rem 1.125rem 0.5rem 2.5rem;
    transition: 200ms ease-in-out;
    max-height: 0;
    padding: 0 1.125rem 0 2.5rem;
    overflow: hidden;

    .part-inner-header {
      display: flex;
      justify-content: space-between;
    }
  }
  &.active {
    .part-inner-name {
      background: ${colors.neutral[70]};
      &-left {
        h3 {
          color: ${colors.neutral[20]};
        }
      }
    }
  }
  &.open {
    .part-inner-headers {
      padding: 0.5rem 1.125rem 0.5rem 2.5rem;
      max-height: 300px;
    }
  }
`;

const PartNavInner = ({ part, partIndex, course, i, icon, close }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState();

  const findHeaders = () => {
    const headers = part.contents.filter(
      (content) => content.type === "header"
    );
    if (headers.length) setHeaders(headers);
    else setHeaders(null);
  };

  useEffect(() => {
    findHeaders();
    setIsOpen(partIndex === i);
    //eslint-disable-next-line
  }, [partIndex]);

  return (
    <StyledPartNavInner
      hasHeaders={!!headers}
      className={`${partIndex === i ? "active" : ""} ${isOpen ? "open" : ""}`}
    >
      <div className="part-inner-name">
        <Link to={`/kurs/${course._id}/${i + 1}`} onClick={close}>
          <div className="part-inner-name-left">
            <SVG
              name={icon || "flag"}
              stroke={partIndex === i ? "white" : colors.primary.hover}
            />
            <h3 className="b2 m0">{part.name}</h3>
          </div>
        </Link>
        {!!headers && (
          <div
            role="button"
            className="open-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <SVG
              name={isOpen ? "chevron-up" : "chevron-down"}
              stroke={partIndex === i ? "white" : colors.primary.hover}
            />
          </div>
        )}
      </div>
      {headers && (
        <div className="part-inner-headers">
          {headers.map((header) => (
            <Link
              onClick={close}
              key={`navigationHeader_${header.uuid}`}
              to={`/kurs/${course._id}/${i + 1}/?h=${header.uuid}`}
            >
              <div className="part-inner-header">
                <p className="m0 b2" header={header}>
                  {header.data.text}
                </p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </StyledPartNavInner>
  );
};
