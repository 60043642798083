import styled from "styled-components";
import DocumentCard from "../../../sharedUI/DocumentCard";

const StyledDocument = styled.div``;

const Document = ({ content }) => {
  return (
    <StyledDocument>
      <DocumentCard file={content.data.file} client />
    </StyledDocument>
  );
};

export default Document;
