import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../sharedUI/Button";
import SVG from "../sharedUI/SVG";
import { breakPoints, colors, measurments, shadows } from "../utils/config";
import PartNav from "./ui/PartNav";

const StyledPartBottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto auto 1fr;
  gap: 0.5rem;
  background: ${colors.neutral[30]};
  align-items: center;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[1]};
  border-top: 1px solid ${colors.neutral[40]};
  padding: 1rem 2rem;
  p.part-title {
    justify-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  a.right {
    justify-self: right;
  }
  button {
    white-space: nowrap;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  ${breakPoints.tablet} {
    padding: 1rem;
  }
  ${breakPoints.mobile} {
    button {
      .svg-wrapper {
        display: block;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      span {
        display: none;
      }
    }
    .vsnitt {
      display: none;
    }
  }
`;

const PartBottomNav = ({ course, part, partIndex }) => {
  const currentPartName = part?.name ? (
    <>
      <span>A</span>
      <span className="vsnitt">vsnitt </span>
      <span>
        {partIndex + 1}: {part.name}
      </span>
    </>
  ) : (
    "Kunskapstest"
  );

  return (
    <StyledPartBottomNav>
      <Link to={partIndex === 0 ? "#" : `/kurs/${course._id}/${partIndex}`}>
        <Button btnType="primary" disabled={partIndex === 0}>
          <div className="svg-wrapper">
            <SVG name="chevron-left" stroke={colors.primary.pressed} />
          </div>
          <span>Tillbaka</span>
        </Button>
      </Link>
      <p className="m0 b1 part-title">{currentPartName}</p>
      <PartNav course={course} partIndex={partIndex} />
      <Link
        to={
          partIndex === course.parts.length
            ? "#"
            : `/kurs/${course._id}/${partIndex + 2}`
        }
        className="right"
      >
        <Button btnType="primary" disabled={partIndex === course.parts.length}>
          <span>Nästa avsnitt</span>
          <div className="svg-wrapper">
            <SVG name="chevron-right" stroke={colors.primary.pressed} />
          </div>
        </Button>
      </Link>
    </StyledPartBottomNav>
  );
};

export default PartBottomNav;
