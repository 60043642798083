import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { useMemo } from "react";
import Skeleton from "../../components/ui/Skeleton";
import IconButton from "../../sharedUI/IconButton";
import { breakPoints, colors, measurments } from "../../utils/config";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import Youtube from "../../components/contentComponents/view/Youtube";
import { youtubeUrl } from "../../utils/youtubeUrl";
import RichText from "../../components/contentComponents/view/RichText";
import { useCache } from "../../caching/caches";
import { useQuery } from "../../utils/useQuery";

const StyledGuide = styled.div`
  padding: 2rem 1rem;
  padding-bottom: 20rem;
  max-width: ${measurments.contentWidthNarrow}px;
  margin-left: auto;
  margin-right: auto;
  .guide-content {
    margin-top: 1rem;
    background: ${colors.neutral[10]};
    padding: 2rem;
    border-radius: ${measurments.borderRadius}px;
  }
  .apparatuses {
    p {
      margin: 0;
    }
    ul {
      padding: 0;
      padding-left: 1rem;
      margin: 0;
      margin-top: 0.5rem;
    }
  }
  .image-wrapper {
    border-radius: ${measurments.borderRadius}px;
    overflow: hidden;
  }
  ${breakPoints.mobile} {
    .guide-content {
      padding: 1rem;
    }
  }
`;

const Guide = () => {
  const { id, tag } = useParams();
  const { loading, state: entries } = useCache("guides");
  const queryCategory = useQuery().get("kategori");

  const entry = useMemo(() => {
    return entries.find((e) => e._id === id) || {};
  }, [id, entries]);

  const youtubeContent = useMemo(() => {
    if (!entry.videoUrl) return null;
    const id = youtubeUrl(entry.videoUrl);
    if (!id) return null;
    return `https://www.youtube.com/embed/${id}`;
  }, [entry.videoUrl]);

  const query = queryCategory
    ? `?kategori=${encodeURIComponent(queryCategory)}`
    : "";

  const backLink = query ? `/ovningsbank/${tag}${query}` : `/ovningsbank`;

  return (
    <StyledGuide className="ptop2">
      <Link to={backLink}>
        <IconButton trans iconLeft="chevron-left">
          Tillbaka
        </IconButton>
      </Link>
      {loading ? (
        <Skeleton identifier="guide" quantity={1} Component={GuidePageLoader} />
      ) : (
        <div className="guide-content">
          <h2 className="h2 m0 mbottom1">{entry.title}</h2>
          {youtubeContent ? (
            <Youtube content={{ data: { embedLink: youtubeContent } }} />
          ) : (
            <div className="image-wrapper">
              <ResponsiveImage file={entry.image} />
            </div>
          )}
          {!!entry.apparatuses?.length && (
            <div className="apparatuses">
              <p className="h3">Redskap</p>
              <ul>
                {entry.apparatuses.map((apparatus, i) => (
                  <li key={`apparatus-${apparatus.name}-${i}`}>
                    {apparatus.name}, {apparatus.quantity} st
                  </li>
                ))}
              </ul>
            </div>
          )}
          <RichText
            content={{
              data: { html: entry.richTextHtml },
            }}
          />
        </div>
      )}
    </StyledGuide>
  );
};

export default Guide;

const StyledGuidePageLoader = styled.div`
  margin-top: 2rem;
  div {
    width: 100%;
    background: ${colors.neutral[40]};
    border-radius: ${measurments.borderRadius}px;
    min-height: 2rem;
    margin-bottom: 1rem;
    &.skeleton-inside {
      background: transparent;
      div {
        min-height: 12px;
        margin-bottom: 8px;
      }
    }
    &.title {
      width: 40%;
    }
    &.image {
      min-height: 400px;
    }
  }
`;

const div = styled.div``;

const GuidePageLoader = () => {
  return (
    <StyledGuidePageLoader>
      <div className="title" />
      <div className="image" />
      <Skeleton
        containerClassName="skeleton-inside"
        identifier={"guide-inside"}
        quantity={15}
        Component={div}
      />
    </StyledGuidePageLoader>
  );
};
