import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../utils/fetcher";
import Input from "../sharedUI/Input";
import Button from "../sharedUI/Button";
import Card from "../sharedUI/Card";
import { breakPoints, measurments } from "../utils/config";
import { useQuery } from "../utils/useQuery";

const StyledResetPassword = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      width: 400px;
      max-width: 100%;
    }
  }
  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    flex-grow: 1;
  }
  .image-container {
    border-radius: ${measurments.borderRadius}px 0 0
      ${measurments.borderRadius}px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
    height: 100vh;
    background: url(/images/placeholder-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .logo-mobile {
    display: none;
  }
  .nav-minimal {
    padding-top: 1rem;
    padding-left: 1rem;
  }
  ${breakPoints.tablet} {
    padding: 1rem;
    .image-container {
      display: none;
    }
    .two-col {
      grid-template-columns: 1fr;
    }
    .container-fw {
      padding: 0;
    }
    .form-container {
      width: 100%;
      .form-wrap {
        width: 100%;
      }
      form {
        width: 100%;
      }
    }
    .logo {
      display: none;
    }
    .logo-mobile {
      display: block;
      width: 24px;
    }
  }
`;

const ResetPassword = ({ login, notify, setAppLoading }) => {
  const token = useQuery().get("t");
  const navigate = useNavigate();
  //States
  const [formData, setFormData] = useState({
    password: "",
    passwordRepeat: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userFromToken, setUserFromToken] = useState();
  const [tokenError, setTokenError] = useState(false);

  //Functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.passwordRepeat) {
      notify.error("Lösenorden stämmer inte överens");
      return;
    }
    setLoading(true);
    const { err } = await fetcher({
      path: "users-no-auth/reset-password",
      method: "POST",
      body: formData,
      auth: token,
    });
    if (err) {
      setLoading(false);
      setMessage("Någonting gick fel");
      return;
    }
    notify.success("Lösenordet återställdes");
    setAppLoading(true);
    login(
      { email: userFromToken.email, password: formData.password },
      () => {
        navigate("/");
      },
      (error) => {
        setLoading(false);
        notify.error(error);
      }
    );
  };

  const handleChange = (e) => {
    setMessage("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "users-no-auth/verify-reset-password-token",
      auth: token,
    });
    if (err) {
      setTokenError(true);
      setAppLoading(false);
      return;
    }
    setUserFromToken(data);
    setAppLoading(false);
  };

  useEffect(() => {
    verifyToken(token);
    //eslint-disable-next-line
  }, [token]);

  return (
    <StyledResetPassword>
      {/* <a href="https://brommagymnasterna.se" target="_blank" rel="noreferrer"> */}
      <div className="nav-minimal">
        <img
          src="/images/logo-horizontal.png"
          alt="Brommagymnasterna"
          className="logo"
        />
        <img
          src="/images/logo-symbol.png"
          alt="Brommagymnasterna"
          className="logo-mobile"
        />
      </div>
      {/* </a> */}
      <div className="two-col">
        <div className="image-container" />
        <div className="form-container">
          <div className="form-wrap">
            {tokenError ? (
              <Card>
                {" "}
                <p className="m0 mbottom1 b1">Länken är inte giltig</p>
                <Link to="/glomt-losenord">
                  <Button btnType="primary">Försök igen</Button>
                </Link>
              </Card>
            ) : (
              <Card>
                <p className="h3 m0 mbottom1">Återställ lösenord</p>

                <form onSubmit={handleSubmit}>
                  <Input
                    type="password"
                    value={formData.password}
                    name="password"
                    disabled={loading}
                    onChange={handleChange}
                    label="Nytt lösenord"
                  />
                  <Input
                    type="password"
                    value={formData.passwordRepeat}
                    name="passwordRepeat"
                    disabled={loading}
                    onChange={handleChange}
                    label="Upprepa nytt lösenord"
                  />
                  {message && <p className="m0 b3 mbottom1">{message}</p>}
                  <div className="flex">
                    <Button
                      className="b2"
                      type="submit"
                      disabled={loading}
                      btnType="primary"
                    >
                      {loading ? "Uppdaterar..." : "Uppdatera lösenord"}
                    </Button>
                  </div>
                </form>
              </Card>
            )}
          </div>
        </div>
      </div>
    </StyledResetPassword>
  );
};

export default ResetPassword;
