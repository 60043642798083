import styled from "styled-components";
import { breakPoints, measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";
import { pdfUrl } from "../../utils/pdfUrl";

const StyledPlanCard = styled.div`
  margin-bottom: 1rem;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1.2rem 1.5rem;
  .button-container {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
  }

  ${breakPoints.mobile} {
    display: block;
    padding: 1rem;
    .button-container {
      margin-top: 1rem;
    }
  }
`;

const PlanCard = ({ entry }) => {
  return (
    <StyledPlanCard>
      <p className="h3 m0">{entry.title}</p>
      <div className="button-container">
        <a href={pdfUrl(entry.file)} target="_blank" rel="noopener noreferrer">
          <Button btnType="neutral">Öppna</Button>
        </a>
        <a
          href={pdfUrl(entry.file, true)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Ladda ned</Button>
        </a>
      </div>
    </StyledPlanCard>
  );
};

export default PlanCard;
