import { useState } from "react";
import styled from "styled-components";
import { colors, measurments } from "../utils/config";
import SVG from "./SVG";

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  label {
    display: block;
    margin-bottom: 0.25rem;
    color: ${colors.neutral[90]};
  }
  input,
  textarea,
  select {
    padding: 0.375rem 0.75rem;
    border-radius: ${measurments.borderRadius}px;
    border: 1px solid ${colors.neutral[50]};
    background: ${colors.neutral[10]};
    width: 100%;
    resize: none;
  }
  margin-bottom: 0.95rem;
  .input-component-container {
    position: relative;
    .input-eye-toggle {
      position: absolute;
      top: 0;
      right: 0.75rem;
      bottom: 0;
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
`;

const Input = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <StyledInput
      className={`${props.className} ${
        passwordVisible ? "password-visible" : ""
      }`}
    >
      {!!props.label && (
        <label htmlFor={props.id || props.name} className="b2">
          {props.label}
        </label>
      )}
      <div className="input-component-container">
        <InputInner {...props} passwordVisible={passwordVisible} />
        {props.type === "password" && (
          <div
            className="input-eye-toggle"
            role="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? (
              <SVG name="eye-closed" stroke={colors.neutral[90]} />
            ) : (
              <SVG name="eye" stroke={colors.neutral[90]} />
            )}
          </div>
        )}
      </div>
    </StyledInput>
  );
};

const InputInner = (props) => {
  const { passwordVisible, ...inputProps } = props;
  if (props.type === "textarea")
    return <textarea {...props} id={props.id || props.name} className="b2" />;
  if (props.type === "select")
    return (
      <select {...props} value="" id={props.id || props.name} className="b2">
        <option value="" disabled>
          {props.placeholder}
        </option>
        {props.children}
      </select>
    );
  return (
    <input
      {...inputProps}
      id={props.id || props.name}
      className="b2"
      type={
        props.type === "password"
          ? passwordVisible
            ? "text"
            : "password"
          : props.type
      }
    />
  );
};

export default Input;
