import { useEffect, useState } from "react";
import styled from "styled-components";
import { Loader } from "../../../sharedUI/FullScreenLoader";
import IconButton from "../../../sharedUI/IconButton";
import SVG from "../../../sharedUI/SVG";
import { colors, measurments } from "../../../utils/config";
import { Link } from "react-router-dom";

const StyledKnowledgeTest = styled.div`
  .start-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      max-width: 40ch;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .icon-button-primary {
    background: ${colors.primary.main};
    &:hover {
      background: ${colors.primary.hover};
    }
  }
  .icon-button-neutral {
    border: 1px solid ${colors.neutral[50]};
  }
  .questions-container {
    .options-container {
      .option {
        margin-bottom: 1rem;
        cursor: pointer;
        transition: 100ms ease-in-out;
        border: 1px solid ${colors.neutral[40]};
        background: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        .checkbox {
          margin-right: 10px;
          width: 16px;
          height: 16px;
          background: ${colors.primary.bgColor};
          border: 1px solid ${colors.neutral[50]};
          border-radius: ${measurments.borderRadius}px;
          transition: 100ms ease-in-out;
          svg {
            transition: 100ms ease-in-out;
            opacity: 0;
          }
        }
        p {
          transition: 100ms ease-in-out;

          color: ${colors.neutral[70]};
        }
        padding: 0.5rem 0.8rem;
        &.selected {
          background: ${colors.primary.hover};
          .checkbox {
            background: white;
            border-color: white;
            svg {
              opacity: 1;
            }
          }
          p {
            color: ${colors.neutral[30]};
          }
        }
      }
    }
  }
  .finish-container-result {
    text-align: center;
    padding: 3rem 1rem;
    .errors {
      margin-bottom: 4rem;
      margin-top: 3rem;
      .res {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        color: ${colors.neutral[80]};
        margin-bottom: 0.7rem;
        .res-error {
          background: ${colors.danger.bgColor};
          color: ${colors.danger.main};
          border: 1px solid ${colors.danger.border};
          border-radius: 50px;
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
`;

const KnowledgeTest = ({ course, logFinish }) => {
  const [currentView, setCurrentView] = useState("start");
  const questions = course.testQuestions;
  const [answers, setAnswers] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [result, setResult] = useState();

  const selectOption = (qi, option) => {
    const ans = Array.from(answers);
    ans[qi] = option;
    setAnswers(ans);
  };

  const checkResults = () => {
    setTimeout(() => {
      const tempRes = [];
      questions.forEach((question, i) => {
        if (question.correctAnswer === answers[i]) tempRes.push(1);
        else tempRes.push(0);
      });
      const score = tempRes.reduce((acc, curr) => {
        return acc + curr;
      });
      const pass = score === questions.length;
      if (pass) logFinish();
      setResult({
        res: tempRes,
        score,
        pass,
      });
    }, 1500);
  };

  const setInitialAnswers = () => {
    let tempAns = [];
    if (questions) {
      for (let i = 0; i < questions.length; i++) {
        tempAns.push(null);
      }
    }
    setAnswers(tempAns);
  };

  const resetTest = () => {
    setInitialAnswers();
    setCurrentView("start");
    setQuestionIndex(0);
    setResult(null);
  };

  useEffect(() => {
    setInitialAnswers();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentView === "finish") {
      checkResults();
    }
    //eslint-disable-next-line
  }, [currentView]);

  return (
    <StyledKnowledgeTest>
      {currentView === "start" && (
        <div className="start-container">
          <p className="m0 mbottom1 h2">Kunskapstest</p>
          <p className="b1 mbottom2 m0">
            Här kommer ett kunskapstest för den del du just genomfört, det är
            kryssfrågor och du väljer ett av svarsalternativen.
          </p>
          <IconButton
            iconRight="play"
            className="icon-button-primary"
            onClick={() => setCurrentView("questions")}
          >
            Starta test
          </IconButton>
        </div>
      )}
      {currentView === "questions" && (
        <div className="questions-container">
          <TestProgressBar
            questionIndex={questionIndex}
            questions={questions}
          />
          <p className="m0 h3 mbottom1">{questions[questionIndex].question}</p>
          <div className="options-container">
            {questions[questionIndex].options.map((option) => (
              <div
                className={`option ${
                  answers[questionIndex] === option ? "selected" : ""
                }`}
                key={option}
                onClick={() => selectOption(questionIndex, option)}
              >
                <div className="checkbox">
                  <SVG name="check" stroke={colors.primary.hover} />
                </div>
                <p className="m0 b2">{option}</p>
              </div>
            ))}
          </div>
          <div className="flex jcsb mtop2">
            {questionIndex === 0 ? (
              <div />
            ) : (
              <IconButton
                onClick={() => setQuestionIndex(questionIndex - 1)}
                iconLeft={"chevron-left"}
                className="icon-button-neutral"
              >
                Föregående fråga
              </IconButton>
            )}
            {questionIndex === questions.length - 1 ? (
              <IconButton
                onClick={() => setCurrentView("finish")}
                iconRight={"chevron-right"}
                className="icon-button-primary"
              >
                Rätta
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setQuestionIndex(questionIndex + 1)}
                iconRight={"chevron-right"}
                className="icon-button-primary"
              >
                Nästa fråga
              </IconButton>
            )}
          </div>
        </div>
      )}
      {currentView === "finish" && (
        <div className="finish-container">
          {result ? (
            <div className="finish-container-result">
              {result.pass ? (
                <>
                  <div className="large-icon">
                    <SVG name="pokal" stroke="black" />
                  </div>
                  <h2 className="m0 mtop1 mbottom1">Bra jobbat!</h2>
                  <p>Du har klarat kursen.</p>
                  <div className="success-buttons flex jc mtop2">
                    <Link to="/">
                      <IconButton iconRight="chevron-right" btnType="primary">
                        Avsluta kurs
                      </IconButton>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="large-icon">
                    <SVG name="retry" stroke="black" />
                  </div>
                  <h2 className="m0 mtop1 mbottom1">Nästan!</h2>
                  <p>
                    Du klarade {result.score}/{questions.length} frågor.
                  </p>
                  <div className="errors">
                    {result.res.map((res, i) => {
                      if (res === 1) return null;
                      return (
                        <div className="res" key={`res_${i}`}>
                          <p className="res-question m0">
                            Fråga {i + 1}: {questions[i].question}
                          </p>
                          <p className="m0 res-error">Fel</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex jc">
                    <IconButton
                      onClick={resetTest}
                      iconLeft="refresh-ccw"
                      stroke="black"
                      btnType="neutral"
                    >
                      Gör om testet
                    </IconButton>
                  </div>
                </>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
    </StyledKnowledgeTest>
  );
};

export default KnowledgeTest;

const StyledTestProgressBar = styled.div`
  margin-bottom: 1rem;
  .test-progress-bar {
    height: 8px;
    width: 100%;
    border-radius: ${measurments.borderRadius}px;
    background-color: ${colors.neutral[40]};
    margin-bottom: 0.5rem;
    &-inner {
      height: 8px;
      transition: 200ms ease-in-out;
      width: ${({ percent }) => percent}%;
      background: ${colors.primary.main};
      border-radius: ${measurments.borderRadius}px;
    }
  }
  p {
    color: ${colors.neutral[70]};
    font-weight: 500;
    margin: 0;
  }
`;

const TestProgressBar = ({ questions, questionIndex }) => {
  const getPercent = () => {
    const length = questions.length;
    const fract = (questionIndex + 1) / length;
    return Math.ceil(fract * 100);
  };

  return (
    <StyledTestProgressBar percent={getPercent()}>
      <div className="test-progress-bar">
        <div className="test-progress-bar-inner" />
      </div>
      <p className="test-prgress-bar-text">
        Fråga {questionIndex + 1} / {questions.length}
      </p>
    </StyledTestProgressBar>
  );
};
