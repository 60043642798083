import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../sharedUI/Button";
import Card from "../sharedUI/Card";
import { Loader } from "../sharedUI/FullScreenLoader";
import Input from "../sharedUI/Input";
import { breakPoints, measurments } from "../utils/config";
import { fetcher } from "../utils/fetcher";
import { useQuery } from "../utils/useQuery";

const StyledNewUser = styled.div`
  overflow-y: scroll;
  padding-bottom: 12rem;
  .center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .form-container {
    padding-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      width: 400px;
      max-width: 100%;
    }
  }
  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    flex-grow: 1;
  }
  .image-container {
    border-radius: ${measurments.borderRadius}px 0 0
      ${measurments.borderRadius}px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
    height: 100vh;
    background: url(/images/placeholder-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .logo-mobile {
    display: none;
  }
  .nav-minimal {
    padding-top: 1rem;
    padding-left: 1rem;
  }
  ${breakPoints.tablet} {
    padding: 1rem;
    .image-container {
      display: none;
    }
    .two-col {
      grid-template-columns: 1fr;
    }
    .container-fw {
      padding: 0;
    }
    .form-container {
      width: 100%;
      .form-wrap {
        width: 100%;
      }
      form {
        width: 100%;
      }
    }
    .logo {
      display: none;
    }
    .logo-mobile {
      display: block;
      width: 24px;
    }
  }
`;

const NewUser = ({ login, globalState, setGlobalState, notify }) => {
  const navigate = useNavigate();
  const urlToken = useQuery().get("t");

  //States
  const [formData, setFormData] = useState({
    repeatPassword: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  //Functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.repeatPassword) {
      notify.error("Lösenorden stämmer inte överens");
      return;
    }
    setLoading(true);
    const { err } = await fetcher({
      path: `users-no-auth/create-account-from-register-link/${urlToken}`,
      method: "POST",
      body: formData,
    });
    if (err) {
      const errorMessage = err.toString().replace("Error: ", "");
      setLoading(false);
      if (errorMessage === "Email exists") {
        notify.error("Mailadressen är redan registrerad");
      } else {
        notify.error("Lösenordet uppfyller inte kraven");
      }
      return;
    }
    login(
      { email: formData.email, password: formData.password },
      (data) => {
        notify.success(`Välkommen ${data.user.firstName}!`);
      },
      (error) => {
        setLoading(false);
        notify.success(error);
      }
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "users-no-auth/verify-register-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const checkUser = async () => {
    console.log("checking user");
    const storedToken = localStorage.getItem("bg-kurswebb-user-token");
    if (globalState.user) navigate("/", { replace: true });
    else if (storedToken) {
      setGlobalState({
        ...globalState,
        appLoading: true,
      });

      const user = await verifyToken(storedToken);
      if (!user) {
        localStorage.removeItem("bg-kurswebb-user-token");
        setGlobalState({
          ...globalState,
          appLoading: false,
        });
        return;
      }
      setGlobalState({
        ...globalState,
        user,
        token: storedToken,
      });
    } else {
      setGlobalState({
        ...globalState,
        appLoading: false,
      });
      localStorage.removeItem("bg-kurswebb-user-token");
    }
  };

  const confirmUrlToken = async () => {
    const { err } = await fetcher({
      path: `users-no-auth/verify-free-register-token/${urlToken}`,
    });
    console.log("token-err_ ", err);
    if (err) {
      setLoading(false);
      setVerified(false);
    } else {
      setLoading(false);
      setVerified(true);
    }
  };

  //Effects
  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  useEffect(() => {
    confirmUrlToken();
    //eslint-disable-next-line
  }, [urlToken]);

  if (loading)
    return (
      <StyledNewUser>
        <div className="center-wrapper">
          <Loader />
        </div>
      </StyledNewUser>
    );
  else if (!loading && !verified)
    return (
      <StyledNewUser>
        <div className="center-wrapper">
          <h1 className="m0">Felaktig länk</h1>
        </div>
      </StyledNewUser>
    );
  else if (!loading && verified)
    return (
      <StyledNewUser>
        <div className="nav-minimal">
          <img
            src="/images/logo-horizontal.png"
            alt="Brommagymnasterna"
            className="logo"
          />
          <img
            src="/images/logo-symbol.png"
            alt="Brommagymnasterna"
            className="logo-mobile"
          />
        </div>
        {/* </a> */}
        <div className="two-col">
          <div className="image-container" />
          <div className="form-container">
            <div className="form-wrap">
              <h1 className="h1 m0 mbottom1">Tränarportalen</h1>
              <Card>
                <p className="h3 m0 mbottom1">Skapa ditt konto</p>
                <form onSubmit={handleSubmit}>
                  <Input
                    label="Förnamn"
                    type="text"
                    required
                    value={formData.firstName}
                    name="firstName"
                    disabled={loading}
                    onChange={handleChange}
                  />
                  <Input
                    label="Efternamn"
                    type="text"
                    required
                    value={formData.lastName}
                    name="lastName"
                    disabled={loading}
                    onChange={handleChange}
                  />
                  <Input
                    label="Mailadress"
                    desc="Använd den mailadress du har i sportadmin"
                    type="email"
                    required
                    value={formData.email}
                    name="email"
                    disabled={loading}
                    onChange={handleChange}
                  />
                  <Input
                    required
                    label="Välj lösenord"
                    type="password"
                    value={formData.password}
                    name="password"
                    disabled={loading}
                    onChange={handleChange}
                  />
                  <Input
                    required
                    label="Upprepa lösenord"
                    type="password"
                    value={formData.repeatPassword}
                    name="repeatPassword"
                    disabled={loading}
                    onChange={handleChange}
                  />
                  <div className="flex mtop2">
                    <Button
                      className="b2"
                      type="submit"
                      disabled={loading}
                      btnType="primary"
                    >
                      {loading ? "Skapar konto..." : "Skapa konto"}
                    </Button>
                  </div>
                </form>
              </Card>
              <Card className="card mtop1">
                <p className="m0 mbottom1 h3">Lösenordskrav</p>
                <ul>
                  <li>Mins 8 tecken</li>
                  <li>Minst en liten bokstav</li>
                  <li>Minst en stor bokstav</li>
                  <li>Minst en siffra</li>
                </ul>
              </Card>
            </div>
          </div>
        </div>
      </StyledNewUser>
    );
  else return null;
};

export default NewUser;
