import styled from "styled-components";
import { colors, measurments, shadows } from "../utils/config";

const StyledCard = styled.div`
  padding: 1.5rem 1.5rem 1.75rem 1.5rem;
  box-shadow: ${shadows[3]};
  background: ${colors.neutral[10]};
  border-radius: ${measurments.borderRadius}px;
`;

const Card = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};

export default Card;
