import styled from "styled-components";
import SVG from "../sharedUI/SVG";
import { breakPoints, colors, measurments } from "../utils/config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const StyledProfileDropdown = styled.div`
  position: relative;
  .user-icon {
    display: none;
  }
  button {
    padding: 0.375rem 0.75rem;
    background: transparent;
    outline: none !important;
    border: none;
    border: 1px solid ${colors.neutral[30]};
    font-size: 1rem;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: 200ms ease-in-out;
    &:hover {
      background: ${colors.neutral[30]};
      border-color: ${colors.neutral[50]};
    }
  }
  .dropdown-button {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background: ${colors.neutral[10]};
    border-radius: ${measurments.borderRadius}px;
    svg {
      path {
        stroke: black;
      }
    }
  }
  .dropdown {
    min-width: 5rem;
    top: 1.7rem;
    opacity: 0;
    transition: 200ms ease-in-out;
    pointer-events: none;
    position: absolute;
    left: -5rem;
    right: 0;
    margin-top: 1rem;
    border-radius: ${measurments.borderRadius}px;
    background: ${colors.neutral[10]};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    .dropdown-item {
      border-radius: 0 0 4px 4px;
      &:first-of-type {
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid ${colors.neutral[40]};
      }
    }
  }
  &.expanded .dropdown {
    opacity: 100%;
    pointer-events: initial;
  }
  ${breakPoints.mobile} {
    button.dropdown-button {
      padding: 0.5rem 0.75rem;
    }
    .user-icon {
      display: block;
    }
    .username {
      display: none;
    }
  }
`;

const ProfileDropdown = ({ signOut, user }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleClick = (type) => {
    if (type === "signOut") signOut();
    if (type === "profile") navigate("/profil");
    setExpanded(false);
  };

  return (
    <StyledProfileDropdown className={expanded ? "expanded" : ""}>
      <button
        className="dropdown-button"
        onClick={() => setExpanded(!expanded)}
      >
        <p className="m0 username">
          {user.firstName} {user.lastName}
        </p>
        <div className="user-icon">
          <SVG name="user" />
        </div>
        <SVG name={expanded ? "chevron-up" : "chevron-down"} />
      </button>
      <div className="dropdown">
        <button
          className="dropdown-item"
          onClick={() => handleClick("profile")}
        >
          Inställningar
        </button>
        <button
          className="dropdown-item"
          onClick={() => handleClick("signOut")}
        >
          Logga ut
        </button>
      </div>
    </StyledProfileDropdown>
  );
};

export default ProfileDropdown;
