import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/fetcher";
import FullScreenLoader from "../../sharedUI/FullScreenLoader";
import Navigation from "../ui/Navigation";

const ProtectedRoute = ({
  globalState,
  setGlobalState,
  notify,
  setAppLoading,
}) => {
  const { verifyingUser } = globalState;
  const navigate = useNavigate();

  //Functions
  const checkUser = () => {
    const storedToken = localStorage.getItem("bg-kurswebb-user-token");
    const { token, user } = globalState;
    if (token && user) {
      handleAuthenticated();
    } else if (storedToken) {
      handlePreviouslyAuthenticated();
    } else {
      handleUnauthenticated();
    }
  };

  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "users-auth/verify-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const handleAuthenticated = () => {
    finishLoading();
  };

  const handlePreviouslyAuthenticated = async () => {
    const storedToken = localStorage.getItem("bg-kurswebb-user-token");
    const user = await verifyToken(storedToken);
    if (!user) return handleUnauthenticated();
    setGlobalState({
      ...globalState,
      token: storedToken,
      user,
    });
  };

  const handleUnauthenticated = () => {
    navigate("/login", { replace: true });
    finishLoading();
  };

  const finishLoading = () => {
    setGlobalState({
      ...globalState,
      appLoading: false,
      verifyingUser: false,
    });
  };

  //Effects
  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  if (verifyingUser) return <FullScreenLoader active />;
  else
    return (
      <>
        <Navigation globalState={globalState} setGlobalState={setGlobalState} />
        <Outlet
          context={{
            globalState,
            setGlobalState,
            notify,
            setAppLoading,
          }}
        />
      </>
    );
};

export default ProtectedRoute;
