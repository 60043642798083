import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import Button from "../../sharedUI/Button";
import Card from "../../sharedUI/Card";
import Input from "../../sharedUI/Input";
import Tabber from "../../sharedUI/Tabber";
import { breakPoints } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";

const StyledProfile = styled.div`
  .tabber-content {
    margin-top: 1rem;

    .change-password {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    }
    .card {
      max-width: 600px;
      min-width: 400px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    padding-left: 1rem;
  }
  ${breakPoints.tablet} {
    .tabber-content {
      .change-password {
        display: block;
        .card {
          margin-bottom: 1rem;
        }
      }
    }
  }
  ${breakPoints.mobile} {
    .tabber-content {
      .card {
        max-width: revert;
        min-width: revert;
      }
    }
  }
`;

const Profile = () => {
  const { globalState, notify, setGlobalState } = useOutletContext();
  const { user } = globalState;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });

  const handleChangeUser = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = (e) => {
    setPasswordInfo({
      ...passwordInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await fetcher({
      path: "users-auth/users/update-info",
      body: userInfo,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte uppdatera uppgifter");
      setLoading(false);
      return;
    }
    notify.success("Uppgifterna sparades");
    setTimeout(() => {
      notify.success("Logga in på nytt");
      signOut();
    }, 500);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (passwordInfo.newPassword !== passwordInfo.newPasswordRepeat) {
      notify.error("Lösenorden stämmer inte överens");
      return;
    }
    setLoading(true);
    const { err } = await fetcher({
      path: "users-auth/users/update-password",
      body: passwordInfo,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte uppdatera lösenord");
      setLoading(false);
      return;
    }
    notify.success("Lösenordet uppdaterades");
    setLoading(false);
    setActiveTab(0);
    setPasswordInfo({
      newPassword: "",
      currentPassword: "",
      newPasswordRepeat: "",
    });
  };

  const signOut = () => {
    window.localStorage.removeItem("bg-kurswebb-user-token");
    navigate("/login");
    setGlobalState({
      ...globalState,
      user: null,
      token: null,
    });
  };

  return (
    <StyledProfile className="content ptop2">
      <h1 className="h1 m0 mtop1 mbottom1">Min profil</h1>
      <Tabber
        tabs={["Dina uppgifter", "Byt lösenord"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="tabber-content">
        {activeTab === 0 && (
          <>
            {isEdit ? (
              <>
                <Card className="card">
                  <p className="m0 b2">
                    Om du ändrar dina uppgifter så måste du logga in igen
                  </p>
                </Card>
                <Card className="card">
                  <form onSubmit={handleUpdateUser}>
                    <p className="m0 h3">Förnamn</p>
                    <Input
                      disabled={loading}
                      className="m0 mbottom1 b1"
                      value={userInfo.firstName}
                      name="firstName"
                      type="text"
                      placeholder="Förnamn"
                      onChange={handleChangeUser}
                    />
                    <p className="m0 h3">Efternamn</p>
                    <Input
                      disabled={loading}
                      className="m0 mbottom1 b1"
                      value={userInfo.lastName}
                      name="lastName"
                      type="text"
                      placeholder="Efternamn"
                      onChange={handleChangeUser}
                    />
                    <p className="m0 h3">Mailadress</p>
                    <Input
                      disabled={loading}
                      className="m0 mbottom1 b1"
                      value={userInfo.email}
                      name="email"
                      type="email"
                      placeholder="Mailadress"
                      onChange={handleChangeUser}
                    />
                    <Button disabled={loading} btnType="primary" type="submit">
                      {loading ? "Sparar..." : "Spara"}
                    </Button>
                  </form>
                </Card>
              </>
            ) : (
              <Card className="card">
                <p className="m0 h3">Förnamn</p>
                <p className="m0 mbottom1 b1">{user.firstName}</p>
                <p className="m0 h3">Efternamn</p>
                <p className="m0 mbottom1 b1">{user.lastName}</p>
                <p className="m0 h3">Mailadress</p>
                <p className="m0 mbottom1 b1">{user.email}</p>
                <Button btnType="primary" onClick={() => setIsEdit(true)}>
                  Ändra
                </Button>
              </Card>
            )}
          </>
        )}
        {activeTab === 1 && (
          <div className="change-password">
            <Card className="card">
              <form onSubmit={handleUpdatePassword}>
                <p className="m0 h3">Nuvarande lösenord</p>
                <Input
                  disabled={loading}
                  className="m0 mbottom1 b1"
                  value={passwordInfo.currentPassword}
                  name="currentPassword"
                  type="password"
                  placeholder="Nuvarande lösenord"
                  onChange={handleChangePassword}
                />
                <p className="m0 h3">Nytt lösenord</p>
                <Input
                  disabled={loading}
                  className="m0 mbottom1 b1"
                  value={passwordInfo.newPassword}
                  name="newPassword"
                  type="password"
                  placeholder="Nytt lösenord"
                  onChange={handleChangePassword}
                />
                <p className="m0 h3">Upprepa nytt lösenord</p>
                <Input
                  disabled={loading}
                  className="m0 mbottom1 b1"
                  value={passwordInfo.newPasswordRepeat}
                  name="newPasswordRepeat"
                  type="password"
                  placeholder="Upprepa nytt lösenord"
                  onChange={handleChangePassword}
                />
                <Button btnType="primary" type="submit" disabled={loading}>
                  {loading ? "Sparar..." : "Spara"}
                </Button>
              </form>
            </Card>
            <Card className="card">
              <p className="m0 mbottom1 h3">Lösenordskrav</p>
              <ul>
                <li>Minst 8 tecken</li>
                <li>Minst en liten bokstav</li>
                <li>Minst en stor bokstav</li>
                <li>Minst en siffra</li>
              </ul>
            </Card>
          </div>
        )}
      </div>
    </StyledProfile>
  );
};

export default Profile;
