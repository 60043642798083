const FORCE_REMOTE_SERVER = true;
const FORCE_REMOTE_LAMBDA = true;

export const server =
  process.env.NODE_ENV === "production" || FORCE_REMOTE_LAMBDA
    ? "https://bg-kurswebb-functions.netlify.app/.netlify/functions/index/"
    : "http://localhost:9999/.netlify/functions/functions/";

export const DOServer =
  process.env.NODE_ENV === "production" || FORCE_REMOTE_SERVER
    ? "https://bg-kurswebb-api.bucketmedia.se/"
    : "http://localhost:4000/";

export const colors = {
  neutral: {
    100: "#0A0A0A",
    90: "#424242",
    80: "#616161",
    70: "#757575",
    60: "#9E9E9E",
    50: "#C2C2C2",
    40: "#E0E0E0",
    30: "#EDEDED",
    20: "#F5F5F5",
    10: "#FFFFFF",
  },
  grey: {
    100: "#B2B2B2",
  },
  primary: {
    main: "#F7E214",
    bgColor: "#EBEBE8",
    pressed: "#2B2B2B",
    hover: "#555552",
    focus: "#EBEBE8",
    border: "#A0A09B",
  },
  success: {
    main: "#21725E",
    bgColor: "#E7FAF5",
    pressed: "#164C3F",
    hover: "#1B5F4E",
    focus: "#C8EBE2",
    border: "#B5D0C9",
  },
  warning: {
    main: "#E0CE2C",
    bgColor: "#FEFADC",
    pressed: "#95891D",
    hover: "#BBAC25",
    focus: "#FDF8CF",
    border: "#F5EFB9",
  },
  danger: {
    main: "#A82525",
    bgColor: "#FFE8E8",
    pressed: "#001793",
    hover: "#001DB8",
    focus: "#E2E7FF",
    border: "#E2B6B6",
  },
};

export const shadows = {
  1: "0px 2px 4px rgba(0, 0, 0, 0.12)",
  2: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  3: "0px 6px 12px rgba(0, 0, 0, 0.1)",
  4: "0px 8px 16px rgba(0, 0, 0, 0.08)",
};

export const measurments = {
  contentWidth: 1000,
  contentWidthNarrow: 748,
  contentWidthCourse: 904,
  contentWidthWide: 1200,
  navHeight: 50,
  borderRadius: 8,
  toolbarWidth: 250,
};

export const breakPoints = {
  tablet: "@media(max-width: 1000px)",
  mobile: "@media(max-width: 640px)",
};
