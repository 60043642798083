import styled from "styled-components";
import { DOServer } from "../../../utils/config";

const StyledResponsiveVideo = styled.video`
  width: 100%;
  display: block;
`;

const ResponsiveVideo = ({ file }) => {
  if (!file) return null;
  if (file.type !== "video") return null;
  return (
    <StyledResponsiveVideo src={`${DOServer}${file.uuid}/main.mp4`} controls />
  );
};

export default ResponsiveVideo;
