export const parseDate = (input) => {
  if (!input) return "-";
  const date = new Date(input);
  const formattedDate = date.toLocaleDateString("sv");
  return formattedDate;
};

export const parseDateTime = (input) => {
  if (!input) return "-";
  const date = new Date(input);
  const formattedDateTime = date.toLocaleString("sv");
  return formattedDateTime;
};
