import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../utils/fetcher";
import Input from "../sharedUI/Input";
import Button from "../sharedUI/Button";
import Card from "../sharedUI/Card";
import { breakPoints, colors, measurments } from "../utils/config";
import Footer from "../components/ui/Footer";

const StyledLogin = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      width: 400px;
      max-width: 100%;
    }
  }
  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    flex-grow: 1;
  }
  .image-container {
    border-radius: ${measurments.borderRadius}px 0 0
      ${measurments.borderRadius}px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
    height: calc(100vh - 60px);
    background: url(/images/placeholder-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .logo-mobile {
    display: none;
  }
  .nav-minimal {
    padding-top: 1rem;
    padding-left: 1rem;
  }
  .footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: ${colors.neutral[30]};
  }
  ${breakPoints.tablet} {
    padding: 1rem;
    .image-container {
      display: none;
    }
    .two-col {
      grid-template-columns: 1fr;
    }
    .container-fw {
      padding: 0;
    }
    .form-container {
      width: 100%;
      .form-wrap {
        width: 100%;
      }
      form {
        width: 100%;
      }
    }
    .logo {
      display: none;
    }
    .logo-mobile {
      display: block;
      width: 24px;
    }
  }
`;

const Login = ({ login, globalState, setGlobalState, notify }) => {
  const navigate = useNavigate();

  //States
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  //Functions
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(
      formData,
      () => {
        console.log("Logged in");
      },
      (error) => {
        setLoading(false);
        notify.error(error);
      }
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "users-auth/verify-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const checkUser = async () => {
    const storedToken = localStorage.getItem("bg-kurswebb-user-token");
    if (globalState.user) navigate("/", { replace: true });
    else if (storedToken) {
      setGlobalState({
        ...globalState,
        appLoading: true,
      });

      const user = await verifyToken(storedToken);
      if (!user) {
        localStorage.removeItem("bg-kurswebb-user-token");
        setGlobalState({
          ...globalState,
          appLoading: false,
        });
        return;
      }
      setGlobalState({
        ...globalState,
        user,
        token: storedToken,
      });
    } else {
      localStorage.removeItem("bg-kurswebb-user-token");
      setGlobalState({
        ...globalState,
        appLoading: false,
      });
    }
  };

  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  return (
    <StyledLogin>
      <div className="nav-minimal">
        <img
          src="/images/logo-horizontal.png"
          alt="Brommagymnasterna"
          className="logo"
        />
        <img
          src="/images/logo-symbol.png"
          alt="Brommagymnasterna"
          className="logo-mobile"
        />
      </div>
      <div className="two-col">
        <div className="image-container" />
        <div className="form-container">
          <div className="form-wrap">
            <h1 className="h1 m0 mbottom1">Tränarportalen</h1>
            <Card>
              <p className="h3 m0 mbottom1">Logga in</p>
              <form onSubmit={handleSubmit}>
                <Input
                  type="email"
                  value={formData.email}
                  name="email"
                  autoComplete="email"
                  disabled={loading}
                  onChange={handleChange}
                  placeholder="din.epost@mail.se"
                  label="Mailadress"
                />
                <Input
                  type="password"
                  value={formData.password}
                  name="password"
                  autoComplete="current-password"
                  disabled={loading}
                  onChange={handleChange}
                  label="Lösenord"
                />
                <div className="flex mtop2">
                  <Button
                    className="b2"
                    type="submit"
                    disabled={loading}
                    btnType="primary"
                  >
                    {loading ? "Loggar in..." : "Logga in"}
                  </Button>
                  <Link to="/glomt-losenord">
                    <Button
                      type="button"
                      disabled={loading}
                      btnType="neutral"
                      className="b2 mleft1"
                    >
                      Glömt lösenord?
                    </Button>
                  </Link>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <Footer />
      </div>
    </StyledLogin>
  );
};

export default Login;
