import styled from "styled-components";
import CourseCard, {
  CourseCardLoader,
} from "../../components/cards/CourseCard";
import Skeleton from "../../components/ui/Skeleton";
import { useCache } from "../../caching/caches";
import { useMemo, useState } from "react";
import Tag from "../../sharedUI/Tag";

const StyledCourses = styled.div`
  margin-top: 2rem;
  padding-bottom: 20rem;
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const Courses = () => {
  const tags = ["Påbörjade", "Avslutade"];

  const [selectedTags, setSelectedTags] = useState([""]);

  const toggleTag = (tag) => {
    const updated = [...selectedTags];
    const index = selectedTags.indexOf(tag);
    if (index === -1) updated.push(tag);
    else updated.splice(index, 1);
    setSelectedTags(updated);
  };

  const { state: _courses, loading: coursesLoading } = useCache("courses");
  const { state: progresses, loading: progressLoading } =
    useCache("progresses");

  const loading = useMemo(() => {
    return coursesLoading || progressLoading;
  }, [coursesLoading, progressLoading]);

  const courses = useMemo(() => {
    return _courses
      .map((course) => ({
        ...course,
        progress: progresses.find((progress) => progress.course === course._id),
      }))
      .filter((course) => {
        if (selectedTags.includes("Avslutade")) {
          return course.progress?.completionPercent === 100;
        }
        if (selectedTags.includes("Påbörjade")) {
          return course.progress && course.progress.completionPercent !== 100;
        }
        return true;
      });
  }, [_courses, progresses, selectedTags]);

  return (
    <StyledCourses className="content">
      <h1 className="mtop2 mbottom2">Grundutbildning</h1>
      <div className="tags-container">
        {tags.map((tag, i) => (
          <Tag
            key={`tag_${tag}_${i}`}
            tagName={tag}
            selected={selectedTags.includes(tag)}
            select={() => toggleTag(tag)}
          />
        ))}
      </div>
      {loading ? (
        <Skeleton
          identifier="course-card"
          quantity={3}
          Component={CourseCardLoader}
        />
      ) : courses.length ? (
        courses.map((course) => <CourseCard key={course._id} course={course} />)
      ) : (
        <p>Inga utbildningar hittades</p>
      )}
    </StyledCourses>
  );
};

export default Courses;
