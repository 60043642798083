import React, { useEffect, useMemo } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import KnowledgeTest from "../../components/contentComponents/view/KnowledgeTest";
import ViewableContent from "../../components/contentComponents/view/ViewableContent";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import FullScreenLoader from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { colors, measurments, shadows } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";
import { useQuery } from "../../utils/useQuery";
import PartBottomNav from "../../components/PartBottomNav";
import { useCache } from "../../caching/caches";

const StyledCoursePart = styled.div`
  max-width: ${measurments.contentWidthNarrow}px;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10rem;
  .n90,
  p {
    color: ${colors.neutral[90]};
  }
  .n100,
  h1,
  h2,
  h3,
  h4 {
    color: ${colors.neutral[100]};
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  .main-image {
    border-radius: ${measurments.borderRadius}px;
    margin-bottom: 1rem;
  }
  .knowledge-text-wrapper {
    margin-top: 1rem;
    padding: 1rem;
    background: ${colors.neutral[10]};
    box-shadow: ${shadows[3]};
    border-radius: ${measurments.borderRadius}px;
  }
  padding-bottom: 40vh;
`;

const CoursePart = () => {
  const { id, paramPartIndex } = useParams();
  const { globalState } = useOutletContext();
  const partIndex = parseInt(paramPartIndex) - 1;
  const h = useQuery().get("h");

  const { state: courses, loading } = useCache("courses");
  const { refetch: refetchProgress } = useCache("progresses");

  const course = useMemo(() => {
    return courses.find((course) => course._id === id);
  }, [courses, id]);

  let sentProgressForIdentifier = "";
  const logProgress = async () => {
    if (!course) return;
    if (sentProgressForIdentifier === `${id}_${partIndex}`) return;
    sentProgressForIdentifier = `${id}_${partIndex}`;
    if (partIndex >= course.parts.length) return;
    await fetcher({
      path: `users-auth/log-course-progress/${id}/?i=${partIndex}`,
      auth: globalState.token,
    });
    refetchProgress();
  };

  const logFinish = async () => {
    await fetcher({
      path: `users-auth/log-course-progress/${id}/?i=${
        partIndex - 1
      }&completed=42`,
      auth: globalState.token,
    });
    refetchProgress();
  };

  const scrollToHeader = () => {
    if (!course) return;
    if (h) {
      const header = document.getElementById(h);
      if (!header) return;
      const headerTopPosition = header.getBoundingClientRect().top;
      window.scrollTo({
        top: headerTopPosition - 80,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    logProgress();
    //eslint-disable-next-line
  }, [id, partIndex, course]);

  useEffect(() => {
    scrollToHeader();
    //eslint-disable-next-line
  }, [course, h, partIndex]);

  if (loading) return <FullScreenLoader />;
  if (!course) return <p>Kunde inte ladda kursinnehåll</p>;
  return (
    <StyledCoursePart className="ptop2">
      <div>
        <Link to={"/kurser"}>
          <IconButton trans iconLeft="chevron-left">
            Lämna utbildning
          </IconButton>
        </Link>
        {partIndex === 0 && (
          <>
            <h1 className="h1 m0 mtop1">{course.name}</h1>
            <p className="n90 b1">
              {course.descriptionLong.map((p, i) => (
                <React.Fragment key={`course_description_${p}_${i}`}>
                  {p}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </>
        )}
      </div>
      {partIndex === 0 && (
        <ResponsiveImage file={course.mainImage} className="main-image" />
      )}
      <div className="course-main">
        <div className="knowledge-text-wrapper mbottom1">
          {partIndex === course.parts.length ? (
            <KnowledgeTest course={course} logFinish={logFinish} />
          ) : (
            <>
              <h2 className="h2 m0">{course.parts[partIndex].name}</h2>
              {course.parts[partIndex].contents.map((content) => (
                <ViewableContent content={content} key={content.uuid} />
              ))}
            </>
          )}
        </div>
      </div>
      <PartBottomNav
        course={course}
        part={course.parts[partIndex]}
        partIndex={partIndex}
      />
    </StyledCoursePart>
  );
};

export default CoursePart;
