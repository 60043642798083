import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../sharedUI/Input";
import Button from "../sharedUI/Button";
import Card from "../sharedUI/Card";
import { breakPoints, measurments } from "../utils/config";
import { fetcher } from "../utils/fetcher";

const StyledForgotPassword = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      width: 400px;
      max-width: 100%;
    }
  }
  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    flex-grow: 1;
  }
  .image-container {
    border-radius: ${measurments.borderRadius}px 0 0
      ${measurments.borderRadius}px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
    height: 100vh;
    background: url(/images/placeholder-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .logo-mobile {
    display: none;
  }
  .nav-minimal {
    padding-top: 1rem;
    padding-left: 1rem;
  }
  ${breakPoints.tablet} {
    padding: 1rem;
    .image-container {
      display: none;
    }
    .two-col {
      grid-template-columns: 1fr;
    }
    .container-fw {
      padding: 0;
    }
    .form-container {
      width: 100%;
      .form-wrap {
        width: 100%;
      }
      form {
        width: 100%;
      }
    }
    .logo {
      display: none;
    }
    .logo-mobile {
      display: block;
      width: 24px;
    }
  }
`;

const ForgotPassword = ({ setAppLoading }) => {
  //States
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [finished, setFinished] = useState(false);

  //Functions
  const handleSubmit = async (e) => {
    setMessage("");
    e.preventDefault();
    setLoading(true);
    const { err } = await fetcher({
      path: "users-no-auth/generate-reset-password-link",
      method: "POST",
      body: formData,
    });
    if (err) {
      setLoading(false);
      setMessage("Någonting gick fel");
      return;
    }
    setFinished(true);
    setLoading(false);
    setMessage(
      "Om mailadressen finns registrerad så har vidare instruktioner skickats på mail"
    );
  };

  const handleChange = (e) => {
    setMessage("");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setAppLoading(false);
    //eslint-disable-next-line
  }, []);

  return (
    <StyledForgotPassword>
      {/* <a href="https://brommagymnasterna.se" target="_blank" rel="noreferrer"> */}
      <div className="nav-minimal">
        <img
          src="/images/logo-horizontal.png"
          alt="Brommagymnasterna"
          className="logo"
        />
        <img
          src="/images/logo-symbol.png"
          alt="Brommagymnasterna"
          className="logo-mobile"
        />
      </div>
      {/* </a> */}
      <div className="two-col">
        <div className="image-container" />
        <div className="form-container">
          <div className="form-wrap">
            <h1 className="h1 m0 mbottom1">Tränarportalen</h1>
            <Card>
              <p className="h3 m0 mbottom1">Glömt lösenord</p>
              <form onSubmit={handleSubmit}>
                <Input
                  type="email"
                  value={formData.email}
                  name="email"
                  disabled={loading || finished}
                  onChange={handleChange}
                  placeholder="din.epost@mail.se"
                  label="Mailadress"
                  required
                />
                {message && <p className="m0 b1 mbottom1">{message}</p>}
                <div className="flex">
                  <Button
                    className="b2"
                    type="submit"
                    disabled={loading || finished}
                    btnType="primary"
                  >
                    {loading ? "Skickar..." : "Skicka återställningslänk"}
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
