import styled from "styled-components";
import SVG from "./SVG";
import { colors } from "../utils/config";

const StyledTag = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  svg {
    width: 15px;
  }
  transition: 200ms ease-in-out;
  background: ${({ _onWhite }) =>
    _onWhite ? colors.neutral[30] : colors.neutral[10]};
  ${({ selected }) =>
    selected ? "background: " + colors.neutral[100] + ";" : ""}
  ${({ selected }) => (selected ? "color: " + colors.neutral[10] + ";" : "")}
  cursor: ${({ _hasHandler }) => _hasHandler && "pointer"};
  border-radius: 50px;
  padding: 0.25rem 1rem;
  &:hover {
    background: ${({ _onWhite }) =>
      _onWhite ? colors.neutral[50] : colors.neutral[40]};
    ${({ selected }) =>
      selected ? "background: " + colors.neutral[100] + ";" : ""}
  }
  ${({ _bgColor }) => (_bgColor ? "background: " + _bgColor + ";" : "")}
  ${({ _size }) =>
    _size === "small"
      ? "font-size: 0.8rem; line-height: 110%; padding: 0.3rem 0.7rem;"
      : ""}
`;

const Tag = ({ tagName, select, selected, remove, onWhite, bgColor, size }) => {
  const handleClick = () => {
    if (select) select();
    if (remove) remove();
  };

  return (
    <StyledTag
      onClick={handleClick}
      selected={selected}
      _onWhite={onWhite}
      _hasHandler={!!select || !!remove}
      _bgColor={bgColor}
      _size={size}
    >
      <span>{tagName}</span>
      {!!remove && <SVG name="x" />}
    </StyledTag>
  );
};

export default Tag;
