import styled from "styled-components";
import ResponsiveVideo from "../view/ResponsiveVideo";

const StyledVideo = styled.div``;

const Video = ({ content }) => {
  return (
    <StyledVideo>
      <ResponsiveVideo file={content.data.file} />
    </StyledVideo>
  );
};

export default Video;
