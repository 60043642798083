import { Link } from "react-router-dom";
import styled from "styled-components";
import { measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";
import { useMemo } from "react";

const StyledGuideCategoryCard = styled.div`
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  overflow: hidden;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1.5rem 1.5rem 1.2rem 1.5rem;
  justify-content: space-between;
  button {
    white-space: nowrap;
  }
`;

const GuideCategoryCard = ({ category, top, parent }) => {
  const link = useMemo(() => {
    if (top)
      return `/ovningsbank/?kategori=${encodeURIComponent(category.text)}`;
    return `/ovningsbank/${category.text}?kategori=${encodeURIComponent(
      parent
    )}`;
  }, [category, top, parent]);

  const buttonText = useMemo(() => {
    if (top) return "Visa kategori";
    return "Visa övningar";
  }, [top]);

  return (
    <Link to={link}>
      <StyledGuideCategoryCard>
        <p className="h3 m0">{category.text}</p>
        <Button btnType="neutral">{buttonText}</Button>
      </StyledGuideCategoryCard>
    </Link>
  );
};

export default GuideCategoryCard;
