import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { useMemo } from "react";
import Skeleton from "../../components/ui/Skeleton";
import IconButton from "../../sharedUI/IconButton";
import { breakPoints, colors, measurments } from "../../utils/config";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import ViewableContent from "../../components/contentComponents/view/ViewableContent";
import { useCache } from "../../caching/caches";

const StyledEventPlan = styled.div`
  padding: 2rem 1rem;
  padding-bottom: 20rem;
  max-width: ${measurments.contentWidthNarrow}px;
  margin-left: auto;
  margin-right: auto;
  .plan-content {
    margin-top: 1rem;
    background: ${colors.neutral[10]};
    padding: 2rem;
    border-radius: ${measurments.borderRadius}px;
  }
  .image-wrapper {
    border-radius: ${measurments.borderRadius}px;
    overflow: hidden;
  }
  .view-content-wrapper {
    margin-bottom: 1rem;
  }
  ${breakPoints.mobile} {
    .plan-content {
      padding: 1rem;
    }
  }
`;

const EventPlan = () => {
  const { id } = useParams();

  const { loading, state: entries } = useCache("events");

  const entry = useMemo(() => {
    return entries.find((e) => e._id === id) || {};
  }, [id, entries]);

  return (
    <StyledEventPlan className="ptop2">
      <Link to="/arrangemang">
        <IconButton trans iconLeft="chevron-left">
          Tillbaka
        </IconButton>
      </Link>
      {loading ? (
        <Skeleton identifier="guide" quantity={1} Component={EventPlanLoader} />
      ) : (
        <div className="plan-content">
          <h2 className="h2 m0 mbottom1">{entry.title}</h2>
          <div className="image-wrapper">
            <ResponsiveImage file={entry.image} />
          </div>
          <div className="mtop2">
            {entry.contents.map((content) => (
              <div className="view-content-wrapper" key={content.uuid}>
                <ViewableContent content={content} />
              </div>
            ))}
          </div>
        </div>
      )}
    </StyledEventPlan>
  );
};

export default EventPlan;

const StyledEventPlanPageLoader = styled.div`
  margin-top: 2rem;
  div {
    width: 100%;
    background: ${colors.neutral[40]};
    border-radius: ${measurments.borderRadius}px;
    min-height: 2rem;
    margin-bottom: 1rem;
    &.skeleton-inside {
      background: transparent;
      div {
        min-height: 12px;
        margin-bottom: 8px;
      }
    }
    &.title {
      width: 40%;
    }
    &.image {
      min-height: 400px;
    }
  }
`;

const div = styled.div``;

export const EventPlanLoader = () => {
  return (
    <StyledEventPlanPageLoader>
      <div className="title" />
      <div className="image" />
      <Skeleton
        containerClassName="skeleton-inside"
        identifier={"guide-inside"}
        quantity={15}
        Component={div}
      />
    </StyledEventPlanPageLoader>
  );
};
