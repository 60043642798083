import styled from "styled-components";
import { breakPoints, colors, measurments } from "../../utils/config";

const StyledFooter = styled.footer`
  border-top: 1px solid ${colors.grey[100]};
  height: 60px;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${measurments.contentWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
  ${breakPoints.mobile} {
    height: unset;
    .footer-inner {
      flex-direction: column;
    }
  }
`;

const Footer = () => {
  const phone = "08-128 754 00";
  const mail = "info@brommagymnasterna.se";

  return (
    <StyledFooter>
      <div className="footer-inner">
        <p>Kontakta kansliet</p>
        <a href={`tel:${phone}`} target="_blank" rel="noreferrer noopener">
          {phone}
        </a>
        <a href={`mailto:${mail}`} target="_blank" rel="noreferrer noopener">
          {mail}
        </a>
      </div>
    </StyledFooter>
  );
};

export default Footer;
