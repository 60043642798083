import React, { useMemo } from "react";
import styled from "styled-components";

const StyledSkeleton = styled.div`
  animation: fade 2s ease-in-out infinite;
  @keyframes fade {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 40%;
    }
    100% {
      opacity: 100%;
    }
  }
`;

const Skeleton = ({ Component, quantity, identifier, containerClassName }) => {
  const arr = useMemo(() => {
    return Array(quantity)
      .fill()
      .map((_, i) => `${identifier}_${i}`);
  }, [quantity, identifier]);

  return (
    <StyledSkeleton className={containerClassName}>
      {arr.map((key) => (
        <Component key={key} />
      ))}
    </StyledSkeleton>
  );
};

export default Skeleton;
