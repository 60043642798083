import styled from "styled-components";
import { breakPoints, colors } from "../utils/config";
import { Player } from "@lottiefiles/react-lottie-player";

const StyledFullScreenLoader = styled.div`
  position: absolute;
  inset: 0;
  background: ${colors.neutral[30]};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  .lottie-loader {
    width: 500px;
  }
  &.active {
    opacity: 1;
  }
  ${breakPoints.tablet} {
    .lottie-loader {
      width: 60vw;
    }
  }
`;

const FullScreenLoader = ({ active }) => {
  return (
    <StyledFullScreenLoader className={`${active ? "active" : ""}`}>
      <Loader />
    </StyledFullScreenLoader>
  );
};

export default FullScreenLoader;

export const Loader = () => {
  return (
    <Player
      autoplay
      loop
      src="/lottie/BG_loading_animation_50fps.json"
      className="lottie-loader"
    ></Player>
  );
};
