import styled from "styled-components";
import SVG from "../../sharedUI/SVG";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";

const StyledWelcomeMessage = styled.div`
  position: relative;
  background: white;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  .close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
  }
`;

const WelcomeMessage = () => {
  const { globalState } = useOutletContext();

  const [isDismissedState, setIsDismissedState] = useState(
    localStorage.getItem("has-dismissed-welcome-message") === "true"
  );

  const dismiss = async () => {
    localStorage.setItem("has-dismissed-welcome-message", "true");
    setIsDismissedState(true);
  };

  if (isDismissedState)
    return <h1>Välkommen tillbaka, {globalState.user.firstName}!</h1>;

  return (
    <>
      <h1>Tränarportalen</h1>
      <StyledWelcomeMessage>
        <h2 className="m0 h3 mbottom05">
          Välkommen till Brommagymnasternas tränarportal!
        </h2>
        <p className="m0">
          Här hittar du allt från interna utbildningar till hjälp med teknik
          samt information om våra arrangemang.
        </p>
        <div className="close-button" onClick={dismiss}>
          <SVG name="x" />
        </div>
      </StyledWelcomeMessage>
    </>
  );
};

export default WelcomeMessage;
