import React, { useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./components/wrappers/Layout";
import ProtectedRoute from "./components/wrappers/ProtectedRoute";
import Login from "./routes/Login";
import Home from "./routes/protected/Home";
import Register from "./routes/Register";
import { fetcher } from "./utils/fetcher";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Course from "./routes/protected/Course";
import CoursePart from "./routes/protected/CoursePart";
import FullScreenLoader from "./sharedUI/FullScreenLoader";
import Profile from "./routes/protected/Profile";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPassword from "./routes/ResetPassword";
import NewUser from "./routes/NewUser";
import Courses from "./routes/protected/Courses";
import NotFound from "./routes/NotFound";
import Guides from "./routes/protected/Guides";
import Guide from "./routes/protected/Guide";
import Plans from "./routes/protected/Plans";
import EventPlans from "./routes/protected/EventPlans";
import EventPlan from "./routes/protected/EventPlan";
import { CacheProvider } from "./caching/caches";
import GuidesCategory from "./routes/protected/GuidesCategory";
import DocumentsPage from "./routes/protected/Documents";
import DocumentPage from "./routes/protected/DocumentPage";

const App = () => {
  const [globalState, setGlobalState] = useState({
    user: null,
    token: null,
    appLoading: true,
    verifyingUser: true,
    modal: {
      active: false,
      content: <div />,
    },
  });

  const toastOptions = {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  const notify = {
    info: (m) => toast.info(m, toastOptions),
    error: (m) => toast.error(m, toastOptions),
    success: (m) => toast.success(m, toastOptions),
  };

  const login = async (formData, cb, errCb) => {
    const { err, data } = await fetcher({
      path: "users-no-auth/login",
      method: "POST",
      body: formData,
    });
    if (err) return errCb("Fel e-post eller lösenord");
    setGlobalState({
      ...globalState,
      token: data.token,
      user: data.user,
    });
    localStorage.setItem("bg-kurswebb-user-token", data.token);
    cb(data);
  };

  const modal = (content) => {
    setGlobalState({
      ...globalState,
      modal: {
        active: true,
        content,
      },
    });
  };

  const closeModal = () => {
    setGlobalState({
      ...globalState,
      modal: {
        ...globalState.modal,
        active: false,
      },
    });
  };

  const setAppLoading = (state) => {
    setGlobalState({
      ...globalState,
      appLoading: state,
    });
  };

  return (
    <BrowserRouter>
      <CacheProvider token={globalState.token} notify={notify}>
        <Layout>
          <Routes>
            <Route
              path="login"
              element={
                <Login
                  login={login}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                />
              }
            />
            <Route
              path="register"
              element={
                <Register
                  login={login}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                />
              }
            />
            <Route
              path="user"
              element={
                <NewUser
                  login={login}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                />
              }
            />
            <Route
              path="glomt-losenord"
              element={<ForgotPassword setAppLoading={setAppLoading} />}
            />
            <Route
              path="aterstall-losenord"
              element={
                <ResetPassword
                  notify={notify}
                  login={login}
                  setAppLoading={setAppLoading}
                />
              }
            />
            <Route
              element={
                <ProtectedRoute
                  setAppLoading={setAppLoading}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                  modal={modal}
                  closeModal={closeModal}
                />
              }
            >
              <Route index element={<Home />} />
              <Route path="kurser" element={<Courses />} />
              <Route path="tranarguide" element={<Guides />} />
              <Route path="tranarguide/:tag" element={<GuidesCategory />} />
              <Route path="tranarguide/:tag/:id" element={<Guide />} />
              <Route path="ovningsbank" element={<Guides />} />
              <Route path="ovningsbank/:tag" element={<GuidesCategory />} />
              <Route path="ovningsbank/:tag/:id" element={<Guide />} />
              <Route path="traningsplaneringar" element={<Plans />} />
              <Route path="evenemang" element={<EventPlans />} />
              <Route path="evenemang/:id" element={<EventPlan />} />
              <Route path="arrangemang" element={<EventPlans />} />
              <Route path="arrangemang/:id" element={<EventPlan />} />
              <Route path="dokument" element={<DocumentsPage />} />
              <Route path="dokument/:id" element={<DocumentPage />} />
              <Route path="profil" element={<Profile />} />
              <Route path="kurs/:id" element={<Course />} />
              <Route path="kurs/:id/:paramPartIndex" element={<CoursePart />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Layout>
      </CacheProvider>
      <FullScreenLoader active={globalState.appLoading} />
    </BrowserRouter>
  );
};

export default App;
