import styled from "styled-components";
import { breakPoints, colors, measurments } from "../../utils/config";

const StyledProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  .progress-bar-inner {
    max-width: 192px;
    margin-top: 2px;
    height: 8px;
    border-radius: ${measurments.borderRadius}px;
    background: ${colors.neutral[40]};
    .progress-bar-progress {
      border-radius: ${measurments.borderRadius}px;
      background: #48a474;
      height: 8px;
      width: ${({ progress }) => progress || 0}%;
      transition: 200ms ease-in-out;
    }
  }
  .b2 {
    color: ${colors.neutral[90]};
    margin-top: auto;
  }
  ${breakPoints.mobile} {
    flex-direction: row;
    align-items: center;
    .progress-bar-inner {
      width: 100%;
      margin-right: 1rem;
    }
  }
`;

const ProgressBar = (props) => {
  return (
    <StyledProgressBar {...props} role="progressbar">
      <div className="progress-bar-inner">
        <div className="progress-bar-progress" />
      </div>
      <div className="progress-bar-text b2">
        {Math.ceil(props.progress)}% Genomförd
      </div>
    </StyledProgressBar>
  );
};

export default ProgressBar;
