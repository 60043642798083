import { createContext, useContext, useEffect, useState } from "react";
import { fetcher as _fetch } from "../utils/fetcher";

const cacheConfig = [
  {
    name: "guides",
    initialState: [],
    errorMessage: "Kunde inte hämta Övningsbank",
    path: "guide",
  },
  {
    name: "guideCategories",
    initialState: [],
    errorMessage: "Kunde inte hämta Övningsbank",
    path: "guideCategories",
  },
  {
    name: "plans",
    initialState: [],
    errorMessage: "Kunde inte hämta träningsplaneringar",
    path: "plan",
  },
  {
    name: "events",
    initialState: [],
    errorMessage: "Kunde inte hämta arrangemang",
    path: "events",
  },
  {
    name: "documents",
    initialState: [],
    errorMessage: "Kunde inte hämta dokument",
    path: "documents",
  },
  {
    name: "courses",
    initialState: [],
    errorMessage: "Kunde inte hämta kurser",
    path: "courses",
  },
  {
    name: "progresses",
    initialState: [],
    errorMessage: "Kunde inte hämta kursstatus",
    path: "users-auth/progress",
  },
];

const createCaches = (cacheTemplates) => {
  const caches = [];
  cacheTemplates.forEach(({ initialState, path, name, errorMessage }) => {
    let error = null;
    const refetch = async ({
      setState,
      setLoading,
      setError,
      notify,
      token,
    }) => {
      setError(null);
      setLoading(true);
      const { err, data } = await _fetch({ auth: token, path });
      if (err || !data) {
        setError(err);
        notify.error(errorMessage);
        setLoading(false);
        return;
      }
      setState(data);
      setLoading(false);
    };
    const value = {
      state: initialState,
      error,
      refetch,
      loading: true,
    };

    const Cache = createContext();
    caches.push({ Cache, value, name });
  });
  return caches;
};

export const caches = createCaches(cacheConfig);

export const useCache = (namespace) => {
  const cache = caches.find((c) => c.name === namespace) || caches[0] || [];
  if (!cache) console.error("No caches found!");
  const context = useContext(cache.Cache);
  return context;
};

export const CacheProvider = ({ children, i = 0, token, notify }) => {
  const value = caches[i]?.value;
  const [state, setState] = useState(value?.state);
  const [loading, setLoading] = useState(value?.loading);
  const [error, setError] = useState(null);
  const refetch = () =>
    value?.refetch({ setState, setLoading, setError, notify, token });
  const provided = {
    state,
    error,
    loading,
    refetch,
  };

  useEffect(() => {
    if (token) refetch();
    //eslint-disable-next-line
  }, [token]);

  const Cache = caches[i]?.Cache;
  if (Cache)
    return (
      <Cache.Provider value={provided} key={caches[i].name}>
        <CacheProvider i={i + 1} token={token} notify={notify}>
          {children}
        </CacheProvider>
      </Cache.Provider>
    );
  else return children;
};
