import styled from "styled-components";
import Header from "./Header";
import Image from "./Image";
import RichText from "./RichText";
import Video from "./Video";
import Youtube from "./Youtube";
import Document from "./Document";

const StyledViewableContentWrapper = styled.div``;

const ViewableContentWrapper = ({ children }) => {
  return (
    <StyledViewableContentWrapper>{children}</StyledViewableContentWrapper>
  );
};

const ViewableContent = ({ content }) => {
  const { type } = content;
  switch (type) {
    case "richtext":
      return (
        <ViewableContentWrapper>
          <RichText content={content} />
        </ViewableContentWrapper>
      );
    case "image":
      return (
        <ViewableContentWrapper>
          <Image content={content} />
        </ViewableContentWrapper>
      );
    case "document":
      return (
        <ViewableContentWrapper>
          <Document content={content} />
        </ViewableContentWrapper>
      );
    case "ytvideo":
      return (
        <ViewableContentWrapper>
          <Youtube content={content} />
        </ViewableContentWrapper>
      );
    case "video":
      return (
        <ViewableContentWrapper>
          <Video content={content} />
        </ViewableContentWrapper>
      );
    case "header":
      return (
        <ViewableContentWrapper>
          <Header content={content} />
        </ViewableContentWrapper>
      );

    default:
      return null;
  }
};

export default ViewableContent;
