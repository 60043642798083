import styled from "styled-components";
import { useMemo, useState } from "react";
import Skeleton from "../../components/ui/Skeleton";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import Tag from "../../sharedUI/Tag";
import Input from "../../sharedUI/Input";
import EventPlanCard from "../../components/cards/EventPlanCard";
import { useCache } from "../../caching/caches";
import { breakPoints } from "../../utils/config";

const StyledEventPlans = styled.div`
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  padding-bottom: 20rem;
  .desc {
    max-width: 45ch;
  }
  ${breakPoints.mobile} {
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
`;

const EventPlans = () => {
  const { loading, state: entries } = useCache("events");
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchString, setSearchString] = useState("");

  const toggleTag = (tag) => {
    const updated = [...selectedTags];
    const index = selectedTags.indexOf(tag);
    if (index === -1) updated.push(tag);
    else updated.splice(index, 1);
    setSelectedTags(updated);
  };

  const filtered = useMemo(() => {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    let filtered = [...entries];
    filtered = entries.filter(
      (entry) =>
        !entry.endDate ||
        new Date(entry.endDate).getTime() > new Date().getTime() - ONE_DAY
    );
    if (selectedTags.length)
      filtered = filtered.filter((entry) =>
        selectedTags.every((tag) => entry.tags.includes(tag))
      );
    if (searchString)
      filtered = filtered.filter((entry) => {
        const searchLc = searchString.toLowerCase();
        const titleLc = entry.title.toLowerCase();
        return titleLc.includes(searchLc);
      });
    return filtered.sort((a, b) => {
      return new Date(a.eventDate).getTime() > new Date(b.eventDate).getTime()
        ? 1
        : -1;
    });
  }, [selectedTags, searchString, entries]);

  const allTags = useMemo(() => {
    return [...new Set(filtered.flatMap((e) => e.tags))];
  }, [filtered]);

  return (
    <StyledEventPlans className="content">
      <h1 className="mtop2">Arrangemang</h1>
      <p className="mbottom2 desc">
        Här hittar du all info om våra arrangemang i klubben. Allt från PM till
        detaljplaner kommer finnas här.
      </p>
      <div className="top-container">
        <div className="tags-container">
          {allTags.map((tag, i) => (
            <Tag
              key={`tag_${tag}_${i}`}
              tagName={tag}
              selected={selectedTags.includes(tag)}
              select={() => toggleTag(tag)}
            />
          ))}
        </div>
        <div className="buttons-container">
          <Input
            type="search"
            placeholder="Sök arrangemang"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
          />
        </div>
      </div>
      <div className="cards-container">
        {loading ? (
          <Skeleton
            identifier="guides"
            quantity={5}
            Component={CourseCardLoader}
          />
        ) : filtered.length ? (
          filtered.map((entry) => (
            <EventPlanCard key={entry._id} entry={entry} />
          ))
        ) : (
          <p>Inga arrangemang hittades</p>
        )}
      </div>
    </StyledEventPlans>
  );
};

export default EventPlans;
