import styled from "styled-components";
import ResponsiveImage from "../../ui/ResponsiveImage";
import { measurments } from "../../../utils/config";

const StyledImage = styled.div`
  border-radius: ${measurments.borderRadius}px;
  overflow: hidden;
`;

const Image = ({ content }) => {
  return (
    <StyledImage>
      <ResponsiveImage file={content.data.file} />
    </StyledImage>
  );
};

export default Image;
