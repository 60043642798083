import styled from "styled-components";
import { DOServer } from "../../utils/config";

const StyledResponsiveImage = styled.img`
  width: 100%;
  display: block;
`;

const ResponsiveImage = ({ file, className }) => {
  if (!file?.name)
    return <StyledResponsiveImage src="/images/image-placeholder.png" alt="" />;
  // if (file.type !== "image") return null;
  return (
    <StyledResponsiveImage
      className={className}
      src={`${DOServer}${file.uuid}/2000.${file.extension}`}
      srcSet={`${DOServer}${file.uuid}/100.${file.extension} 100w,
      ${DOServer}${file.uuid}/500.${file.extension} 500w,
      ${DOServer}${file.uuid}/1000.${file.extension} 1000w,
      ${DOServer}${file.uuid}/2000.${file.extension} 2000w`}
      alt={file.name}
    />
  );
};

export default ResponsiveImage;
