import styled from "styled-components";
import { useMemo, useState } from "react";
import Skeleton from "../../components/ui/Skeleton";
import GuideEntryCard from "../../components/cards/GuideEntryCard";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import Input from "../../sharedUI/Input";
import { useCache } from "../../caching/caches";
import { useParams, Link } from "react-router-dom";
import IconButton from "../../sharedUI/IconButton";
import { useQuery } from "../../utils/useQuery";

const StyledGuidesCategory = styled.div`
  padding-bottom: 20rem;
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    input {
      min-width: 12rem;
    }
  }
`;

const GuidesCategory = () => {
  const [searchString, setSearchString] = useState("");
  const queryCategory = useQuery().get("kategori");

  const { state: entries, loading } = useCache("guides");
  const { tag } = useParams();

  const filtered = useMemo(() => {
    let filtered = entries.filter((entry) => {
      const categoryNames = entry.categoryModels.map(
        (category) => category.text
      );
      if (!categoryNames.includes(queryCategory)) return false;
      const tagNames = entry.tagModels.map((tag) => tag.text);
      return tagNames.includes(tag);
    });

    if (searchString)
      filtered = filtered.filter((entry) => {
        const searchLc = searchString.toLowerCase();
        const titleLc = entry.title.toLowerCase();
        return titleLc.includes(searchLc);
      });
    return filtered;
  }, [searchString, entries, tag, queryCategory]);

  return (
    <StyledGuidesCategory className="content">
      <h1 className="mbottom1">Övningsbanken</h1>
      <Link to={`/ovningsbank?kategori=${queryCategory}`} className="mbottom2">
        <IconButton trans iconLeft="chevron-left">
          Tillbaka
        </IconButton>
      </Link>
      <div className="top-container">
        <h2>
          {queryCategory} / {tag}
        </h2>
        <div className="buttons-container">
          <Input
            type="search"
            placeholder="Sök övning"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton
          identifier="guides"
          quantity={5}
          Component={CourseCardLoader}
        />
      ) : filtered.length ? (
        filtered.map((entry) => (
          <GuideEntryCard key={entry._id} entry={entry} />
        ))
      ) : (
        <p>Inga övningar hittades</p>
      )}
    </StyledGuidesCategory>
  );
};

export default GuidesCategory;
