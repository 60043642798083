import styled from "styled-components";
import { measurments, shadows } from "../../utils/config";
import { Link } from "react-router-dom";
import Button from "../../sharedUI/Button";
import { useMemo } from "react";
import ResponsiveImage from "../contentComponents/view/ResponsiveImage";

const StyledDocumentEntryCard = styled.div`
  height: 100%;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  .text-container {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    .text-inner {
      flex-grow: 1;
    }
    .button-container {
      margin-top: auto;
      margin-left: auto;
    }
  }
`;

const DocumentEntryCard = ({ entry }) => {
  const image = useMemo(() => {
    return entry.contents.find((block) => block.type === "image")?.data?.file;
  }, [entry]);

  return (
    <Link to={`/dokument/${entry._id}`}>
      <StyledDocumentEntryCard>
        {!!image && <ResponsiveImage file={image} />}
        <div className="text-container">
          <div className="text-inner">
            <p className="h3 m0">{entry.title}</p>
            {!!entry.ingress && <p className="m0">{entry.ingress}</p>}
          </div>
          <div className="button-container">
            <Button btnType="neutral">Öppna</Button>
          </div>
        </div>
      </StyledDocumentEntryCard>
    </Link>
  );
};

export default DocumentEntryCard;
