import styled from "styled-components";

const StyledNotFound = styled.div`
  text-align: center;
`;

const NotFound = () => {
  return (
    <StyledNotFound className="content ptop2">
      <h1 className="h1 m0 mtop2 mbottom1">404</h1>
      <p>Sidan kunde inte hittas</p>
    </StyledNotFound>
  );
};

export default NotFound;
