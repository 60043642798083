import { server } from "./config";

export const fetcher = async ({ ...args }) => {
  const retObj = {};
  const { path, method, body, auth, server: s } = args;
  const url = s || server;
  const headers = {
    "Content-Type": "application/json",
  };
  if (auth) headers.Authorization = "Bearer " + auth;
  try {
    const options = {
      method: method || "GET",
      body: body && JSON.stringify(body),
      headers,
    };
    const res = await fetch(`${url}${path}`, options);
    let result;
    result = await res.json();
    if (!res.ok) throw new Error(result);
    retObj.data = result;
  } catch (e) {
    console.log(e);
    retObj.err = e;
  }
  return retObj;
};
