import styled from "styled-components";
import { useMemo, useState } from "react";
import Skeleton from "../../components/ui/Skeleton";
import PlanCard from "../../components/cards/PlanCard";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import Tag from "../../sharedUI/Tag";
import Input from "../../sharedUI/Input";
import { useCache } from "../../caching/caches";
import { breakPoints } from "../../utils/config";

const StyledPlans = styled.div`
  padding-bottom: 20rem;
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    input {
      min-width: 12rem;
    }
    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  .desc {
    max-width: 70ch;
  }
  ${breakPoints.mobile} {
    .top-container {
      flex-direction: column-reverse;
      .search-input {
        width: 100%;
      }
    }
  }
`;

const Plans = () => {
  const { loading, state: entries } = useCache("plans");

  const [selectedTags, setSelectedTags] = useState([]);
  const [searchString, setSearchString] = useState("");

  const toggleTag = (tag) => {
    const updated = [...selectedTags];
    const index = selectedTags.indexOf(tag);
    if (index === -1) updated.push(tag);
    else updated.splice(index, 1);
    setSelectedTags(updated);
  };

  const allTags = useMemo(() => {
    return [...new Set(entries.flatMap((e) => e.tags))];
  }, [entries]);

  const filtered = useMemo(() => {
    if (!searchString && !selectedTags.length) return entries;
    let filtered = [...entries];
    if (selectedTags.length)
      filtered = filtered.filter((entry) =>
        selectedTags.every((tag) => entry.tags.includes(tag))
      );
    if (searchString)
      filtered = filtered.filter((entry) => {
        const searchLc = searchString.toLowerCase();
        const titleLc = entry.title.toLowerCase();
        return titleLc.includes(searchLc);
      });
    return filtered;
  }, [selectedTags, searchString, entries]);

  const sorted = useMemo(() => {
    return filtered.sort((a, b) => a.sort - b.sort);
  }, [filtered]);

  return (
    <StyledPlans className="content">
      <h1 className="mtop2">Träningsplaneringar</h1>
      <p className="mbottom2 desc">
        Här hittar du färdiga träningsplaneringar till alla våra grupper mellan
        3 och 12 år. Tanken med dessa är att man kan köra dem rakt av eller
        använda dem som inspiration.
      </p>
      <div className="top-container">
        <div className="tags-container">
          {allTags.map((tag, i) => (
            <Tag
              key={`tag_${tag}_${i}`}
              tagName={tag}
              selected={selectedTags.includes(tag)}
              select={() => toggleTag(tag)}
            />
          ))}
        </div>
        <Input
          className="search-input"
          type="search"
          placeholder="Sök träningsplanering"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />
      </div>
      {loading ? (
        <Skeleton
          identifier="plans"
          quantity={5}
          Component={CourseCardLoader}
        />
      ) : sorted.length ? (
        sorted.map((entry) => <PlanCard key={entry._id} entry={entry} />)
      ) : (
        <p>Inga träningsplaneringar hittades</p>
      )}
    </StyledPlans>
  );
};

export default Plans;
