import styled from "styled-components";
import { Link } from "react-router-dom";
import ResponsiveImage from "../ui/ResponsiveImage";
import React from "react";
import { breakPoints, colors, measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";
import ProgressBar from "../ui/ProgressBar";

const StyledCourseCard = styled.div`
  background: ${colors.neutral[10]};
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  display: grid;
  grid-template-columns: ${(measurments.contentWidth / 12) * 2}px auto;
  min-height: ${(measurments.contentWidth / 12) * 2}px;
  margin-bottom: 1rem;
  overflow: hidden;
  .course-image-container {
    img {
      height: 100%;
      min-height: ${(measurments.contentWidth / 12) * 2}px;
      object-fit: cover;
    }
  }
  .course-text-container {
    padding: 1.5rem;
    .h3 {
      color: ${colors.neutral[100]};
    }
    .b1 {
      color: ${colors.neutral[90]};
      margin-top: 8px;
    }
    .course-card-bottom {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 8px;
      .course-card-buttons {
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
      }
    }
  }
  ${breakPoints.mobile} {
    display: block;
    .course-text-container {
      padding: 1rem;
      .course-card-bottom {
        display: block;
        .course-card-buttons {
          display: flex;
          flex-direction: column-reverse;
          gap: 0.5rem;
          margin-top: 1rem;
          justify-content: flex-end;
          button {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const CourseCardLoader = styled.div`
  width: 100%;
  background: ${colors.neutral[40]};
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  min-height: ${(measurments.contentWidth / 12) * 2}px;
  margin-bottom: 1rem;
`;

const CourseCard = ({ course }) => {
  const { progress: status } = course;
  let started = false;
  if (status) {
    started = true;
  }
  return (
    <StyledCourseCard>
      <div className="course-image-container">
        <ResponsiveImage file={course.mainImage} />
      </div>
      <div className="course-text-container">
        <h2 className="h3 m0">{course.name}</h2>
        <p className="b1 m0">
          {course.description.map((p, i) => (
            <React.Fragment key={`${course._id}_${p}_${i}`}>
              {p}
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className="course-card-bottom">
          {started ? (
            <ProgressBar progress={status.completionPercent} />
          ) : (
            <div />
          )}
          {status?.testCompleted ? (
            <div className="course-card-buttons">
              <Link to={`/kurs/${course._id}/${1}`}>
                <Button>Gör om kurs</Button>
              </Link>
            </div>
          ) : (
            <div className="course-card-buttons">
              {started && (
                <Link to={`/kurs/${course._id}/1`}>
                  <Button btnType="neutral">Börja från början</Button>
                </Link>
              )}
              {started ? (
                <Link to={`/kurs/${course._id}/${status.continueIndex}`}>
                  <Button>Fortsätt</Button>
                </Link>
              ) : (
                <Link to={`/kurs/${course._id}/${1}`}>
                  <Button>Starta</Button>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledCourseCard>
  );
};

export default CourseCard;
