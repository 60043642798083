import styled from "styled-components";
import { measurments } from "../../../utils/config";

const StyledYoutube = styled.div`
  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: 2rem;
    border-radius: ${measurments.borderRadius}px;
    overflow: hidden;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Youtube = ({ content }) => {
  return (
    <StyledYoutube>
      <div className="iframe-container">
        <iframe
          width="1920"
          height="1080"
          src={content.data.embedLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </StyledYoutube>
  );
};

export default Youtube;
