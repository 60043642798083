import sanitize from "sanitize-html";
import styled from "styled-components";

const StyledRichText = styled.div`
  a:link {
    text-decoration: underline;
  }
`;

const RichText = ({ content }) => {
  const sanitized = sanitize(content.data.html);
  return <StyledRichText dangerouslySetInnerHTML={{ __html: sanitized }} />;
};

export default RichText;
