import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import FullScreenLoader from "../../sharedUI/FullScreenLoader";

const Course = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/kurs/${id}/1`);
    //eslint-disable-next-line
  }, [id]);

  return <FullScreenLoader active={true} />;
};

export default Course;
