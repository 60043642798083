import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { useMemo } from "react";
import Skeleton from "../../components/ui/Skeleton";
import IconButton from "../../sharedUI/IconButton";
import { breakPoints, colors, measurments } from "../../utils/config";
import ViewableContent from "../../components/contentComponents/view/ViewableContent";
import { useCache } from "../../caching/caches";
import { EventPlanLoader } from "./EventPlan";

const StyledDocumentPage = styled.div`
  padding: 2rem 1rem;
  padding-bottom: 20rem;
  max-width: ${measurments.contentWidthNarrow}px;
  margin-left: auto;
  margin-right: auto;
  .plan-content {
    margin-top: 1rem;
    background: ${colors.neutral[10]};
    padding: 2rem;
    border-radius: ${measurments.borderRadius}px;
  }
  .view-content-wrapper {
    margin-bottom: 1rem;
  }
  ${breakPoints.mobile} {
    .plan-content {
      padding: 1rem;
    }
  }
`;

const DocumentPage = () => {
  const { id } = useParams();

  const { loading, state: entries } = useCache("documents");

  const entry = useMemo(() => {
    return entries.find((e) => e._id === id) || {};
  }, [id, entries]);

  return (
    <StyledDocumentPage className="ptop2">
      <Link to="/dokument">
        <IconButton trans iconLeft="chevron-left">
          Tillbaka
        </IconButton>
      </Link>
      {loading ? (
        <Skeleton
          identifier="document"
          quantity={1}
          Component={EventPlanLoader}
        />
      ) : (
        <div className="plan-content">
          <h2 className="h2 m0 mbottom1">{entry.title}</h2>
          <div className="mtop2">
            {entry.contents.map((content) => (
              <div className="view-content-wrapper" key={content.uuid}>
                <ViewableContent content={content} />
              </div>
            ))}
          </div>
        </div>
      )}
    </StyledDocumentPage>
  );
};

export default DocumentPage;
