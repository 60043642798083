import styled from "styled-components";
import { breakPoints, colors, measurments } from "../utils/config";

const StyledButton = styled.button`
  padding: 0.375rem 1rem;
  border-radius: ${measurments.borderRadius}px;
  border: none;
  background: ${colors.primary.main};
  border: 1px solid ${colors.primary.main};
  cursor: pointer;
  font-size: 1rem;
  transition: 100ms ease-in-out;
  color: ${colors.primary.pressed};
  &:hover {
    background: ${colors.primary.hover};
    color: ${colors.primary.focus};
    border: 1px solid ${colors.primary.hover};
  }
  &:focus {
    outline: none;
    color: ${colors.primary.pressed};
    background: ${colors.primary.main};
    border: 1px solid ${colors.primary.focus};
  }
  &:active {
    outline: none;
    background: ${colors.primary.pressed};
    color: ${colors.primary.focus};
    border: 1px solid ${colors.primary.pressed};
  }
  &:disabled {
    background: ${colors.neutral[20]};
    color: ${colors.neutral[50]};
    border: 1px solid ${colors.neutral[20]};
    cursor: not-allowed;
  }
  &.neutral {
    background: transparent;
    color: ${colors.neutral[90]};
    border-color: ${colors.primary.border};
    border-width: 1px;
    &:hover {
      background: ${colors.neutral[90]};
      color: ${colors.neutral[20]};
    }
  }
  &.danger {
    background: ${colors.danger.bgColor};
    border-color: ${colors.danger.border};
    color: ${colors.danger.main};
    &:hover {
      background: ${colors.danger.main};
      color: white;
    }
  }
  ${breakPoints.tablet} {
    font-size: 0.9rem;
  }
`;

const Button = (props) => {
  return (
    <StyledButton
      {...props}
      type={props.type || "button"}
      className={`${props.className ? props.className : ""} ${
        props.btnType ? props.btnType : ""
      }`}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;
