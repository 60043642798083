import styled from "styled-components";
import { breakPoints, colors, measurments } from "../utils/config";

const StyledTabber = styled.div`
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;

  .tab {
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: ${colors.neutral[70]};
    position: relative;
    transition: 200ms ease-in-out;
    border-radius: ${measurments.borderRadius}px ${measurments.borderRadius}px 0
      0;
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      left: 0;
      width: 0%;
      height: 3px;
      background: transparent;
      transition: 200ms ease-in-out;
    }
    &.active {
      background: ${colors.neutral[10]};
      color: ${colors.neutral[90]};
      &:after {
        background: ${colors.primary.main};
        width: 100%;
      }
    }
  }
  ${breakPoints.tablet} {
    border-bottom: 1px solid ${colors.neutral[40]};
  }
`;

const Tabber = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <StyledTabber>
      {tabs.map((tab, i) => (
        <div
          className={`tab b1 ${activeTab === i ? "active" : ""}`}
          id={`tabber_${tab}`}
          role="banner"
          onClick={() => setActiveTab(i)}
          key={tab}
        >
          {tab}
        </div>
      ))}
    </StyledTabber>
  );
};

export default Tabber;
