import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { colors, measurments } from "../../utils/config";
import Footer from "../ui/Footer";

const StyledLayout = styled.div`
  background: ${colors.neutral[30]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .content {
    max-width: ${measurments.contentWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    &-wide {
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: ${measurments.contentWidthWide}px;
    }
  }

  main {
    flex-grow: 1;
  }
  .danger {
    color: ${colors.danger.main};
  }
`;

const Layout = ({ children }) => {
  let renderFooter = false;
  if (typeof window !== "undefined" && window.location.pathname !== "/login") {
    renderFooter = true;
  }
  return (
    <StyledLayout>
      <main>{children}</main>
      {renderFooter && <Footer />}
      <ToastContainer newestOnTop />
    </StyledLayout>
  );
};

export default Layout;
