import styled from "styled-components";
import { breakPoints, colors, measurments } from "../utils/config";
import SVG from "./SVG";

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: ${measurments.borderRadius}px;
  border: none;
  background: white;
  font-size: 1rem;
  cursor: pointer;
  span {
    ${({ iconLeft }) => (iconLeft ? "margin-left: 0.5rem;" : "")}
    ${({ iconRight }) => (iconRight ? "margin-right: 0.5rem;" : "")}
  }
  transition: 100ms ease-in-out;
  &:hover {
    background: ${colors.neutral[90]};
    color: ${colors.neutral[30]};
    svg {
      path {
        stroke: ${colors.neutral[30]};
      }
    }
  }
  &.trans {
    background: none;
    padding: 0;
    font-size: 1rem;
    &:hover {
      text-decoration: underline;
      background: none;
      color: black;
      svg {
        path {
          stroke: black;
        }
      }
    }
  }
  &.neutral {
    background: ${colors.neutral[40]};
    &:hover {
      background: ${colors.neutral[60]};
      color: black;
      svg {
        path {
          stroke: black;
        }
      }
    }
  }
  &.primary {
    background: ${colors.primary.main};
    color: black;
    &:hover {
      background: ${colors.primary.hover};
      color: white;
      svg {
        path {
          stroke: white;
        }
      }
    }
  }
  ${breakPoints.tablet} {
    font-size: 0.9rem;
  }
`;

const IconButton = (props) => {
  return (
    <StyledIconButton
      {...props}
      className={`${props.className ? props.className : ""} ${
        props.trans ? "trans" : ""
      } ${props.btnType ? props.btnType : ""}`}
      type={props.type || "button"}
    >
      {props.iconLeft && (
        <SVG name={props.iconLeft} stroke={props.stroke} fill={props.fill} />
      )}
      <span>{props.children}</span>
      {props.iconRight && (
        <SVG name={props.iconRight} stroke={props.stroke} fill={props.fill} />
      )}
    </StyledIconButton>
  );
};

export default IconButton;
